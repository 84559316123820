import { useState } from "react"
import { createLevelEvaluation } from "src/api"
import { useDispatch } from "react-redux"
import { fetchLevelEvaluations } from "../../store/levelEvaluations/actions"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"
import { getAPI_CALL_STARTED } from "../gaConstants"
export default () => {
  const [error, setError] = useState(undefined)
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const execute = async (levelEvaluation, companyUUID) => {
    try {
      trackCustomEvent(getAPI_CALL_STARTED("CREATE_LEVEL_EVALUATION"))

      setLoading(true)
      setError(undefined)
      const data = await createLevelEvaluation(levelEvaluation)
      dispatch(fetchLevelEvaluations(companyUUID))
      return data
    } catch (error) {
      Sentry.captureException(error)

      setError(error.message)
      throw error
    } finally {
      setLoading(false)
    }
  }

  return { error, loading, createLevelEvaluation: execute }
}
