import validate from "validate.js"

export const isEmptyForm = form => !form.firstname && !form.lastname && !form.email && !form.companyID && !form.theirref1

export const getErrors = state => {
  return {
    firstname: validateName(state.firstname),
    lastname: validateName(state.lastname),
    email: isValidEmail(state.email),
    companyID: undefined,
    languageTest: isValidLanguage(state.languageTest),
    languageUI: isValidLanguage(state.languageUI),
  }
}

export const hasErrors = errors => {
  return !!errors.firstname || !!errors.lastname || !!errors.email
}

const validateName = name => {
  const errors = []
  if (validate.isEmpty(name)) {
    errors.push("nameRequiredError")
  }
  if (!validate.isString(name)) {
    errors.push("nameShouldBeTextError")
  }
  if (errors.length > 0) {
    return errors
  }
  return false
}

//This method is not working, should be tested
const isValidEmail = email => {
  const res = validate({ email }, { email: { email: true } })
  if (res) {
    return ["emailInvalidError"]
  } else {
    return false
  }
}

const allowedLanguageCodes = ["fr", "eng", "ger", "nl"]

const isValidLanguage = language => allowedLanguageCodes.includes(language)
