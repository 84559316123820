import { getLanguageCode } from "../../../../utils/language"

/* Used for the language selection */
export const languageOptionsTest = [
  { value: getLanguageCode("FR").toLowerCase(), name: getLanguageCode("FR") },
  { value: getLanguageCode("EN").toLowerCase(), name: getLanguageCode("EN") },
  { value: getLanguageCode("DE").toLowerCase(), name: getLanguageCode("DE") },
  { value: getLanguageCode("NL").toLowerCase(), name: getLanguageCode("NL") },
]

export const languageOptionsUI = [
  { value: getLanguageCode("FR").toLowerCase(), name: getLanguageCode("FR") },
  { value: getLanguageCode("EN").toLowerCase(), name: getLanguageCode("EN") },
  { value: getLanguageCode("DE").toLowerCase(), name: getLanguageCode("DE") },
  { value: getLanguageCode("NL").toLowerCase(), name: getLanguageCode("NL") },
]
