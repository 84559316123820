import { useState } from "react"
import useCreateLevelEvaluation from "./useCreateLevelEvalutation"
import useCreatePerson from "./useCreatePerson"
import { v4 as uuid } from "uuid"
import moment from "moment"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"
import { getAPI_CALL_STARTED } from "../gaConstants"
import { useCurrentCompany } from "./useCurrentCompany"
import { getBaseEvaluationTypeForCompany } from "../evaluation-type-utils"
export default () => {
  const [error, setError] = useState(undefined)
  const [loading, setLoading] = useState(false)
  const { error: LError, createLevelEvaluation } = useCreateLevelEvaluation()
  const { error: PError, createPerson } = useCreatePerson()

  const company = useCurrentCompany()
  const baseEvalType = getBaseEvaluationTypeForCompany(company)

  const execute = async (invite, companyUUID, hrUUID) => {
    try {
      setLoading(true)
      setError(undefined)
      /* Create a person object for the new level evaluation */
      trackCustomEvent(getAPI_CALL_STARTED("CREAT_PERSON_LEVEL_EVALUATION"))

      const person = await createPerson(
        {
          firstname: invite.firstname,
          lastname: invite.lastname,
          commonname: `${invite.firstname} ${invite.lastname}`,
          email: invite.email,
          isevaluatedperson: true,
          companyuuid: companyUUID,
          commlangcode: invite.languageUI,
          theirref1: invite.theirref1,
        },
        companyUUID
      )
      trackCustomEvent(getAPI_CALL_STARTED("CREATE_LEVEL_EVALUATION"))

      /* Create a level evaluation for the created person */
      const levelEvaluationUUID = uuid()
      const leveleEvaluation = await createLevelEvaluation(
        {
          uuid: levelEvaluationUUID,
          personuuid: person.uuid,
          evaluatedlanguage: invite.languageTest,
          statusid: 10,
          invitationtimestamp: invite.requesttimestamp,
          expirestimestamp: moment(invite.requesttimestamp)
            .add(365, "days")
            .format("YYYY-MM-DD"),
          finishedtimestamp: null,
          requesttimestamp: invite.requesttimestamp,
          currentstep: 0,
          hruuid: hrUUID,
          signalfinished: invite.signalfinished,
          requesttemplateuuid: invite.requesttemplateuuid,
          letypecode: invite.letypecode ?? baseEvalType,
        },
        companyUUID
      )
      if (LError) {
        setError(LError)
      }
      if (PError) {
        setError(PError)
      }
      return { person, leveleEvaluation }
    } catch (error) {
      Sentry.captureException(error)

      setError(error.message)
      throw error
    } finally {
      setLoading(false)
    }
  }

  return { error, loading, createInvite: execute }
}
