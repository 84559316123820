import React from "react"
import useFormatMessage from "../../../../utils/hooks/useFormatMessage"

const Header = ({ headers }) => {
  const formatMessage = useFormatMessage()
  return (
    <>
      <div className="flex px-5 font-sans text-xs">
        {headers.map(header => (
          <div
            key={header.id}
            className={`text-center md:${header.width} ${
              header.mobileWidth ? header.mobileWidth : "hidden md:block"
            }  py-3 break-all uppercase font-semibold select-none flex flex-row justify-center`}
          >
            <div className="flex flex-row items-center justify-center">{header.text && <span>{formatMessage(header.text)}</span>}</div>
          </div>
        ))}
      </div>
      <div className="bg-gray-300 h-px" />
    </>
  )
}

export default Header
