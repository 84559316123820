import _ from "lodash"
import React, { useState, useEffect } from "react"
import headers from "./utils/headers"
import Invite from "./components/invite"
import { useLocalStorage, useEffectOnce } from "react-use"
import Header from "./components/header"
import GreenLine from "../../layout/greenLine"
import { forEach } from "../../../utils/async/asyncForEach"
import { getErrors, hasErrors, isEmptyForm } from "./utils/validations"
import * as languages from "./utils/languageOptions"
import CSVReader from "react-csv-reader"
import useUser from "../../../utils/hooks/useUser"
import Loader from "../../base/loaders/loader"
import notifications from "../../../utils/notifications"
import Button from "../../base/buttons/regular"
import { defineMessages } from "gatsby-plugin-intl"
import useFormatMessage from "../../../utils/hooks/useFormatMessage"
import useCreateInvite from "../../../utils/hooks/useCreateInvite"
import useCreateInviteList from "../../../utils/hooks/useCreateInviteList"
import moment from "moment"
import InviteSettingsModal from "./components/inviteSettingsModal"
import { useCurrentCompany } from "src/utils/hooks/useCurrentCompany"
import {
  getBaseEvaluationTypeForCompany,
  getLevelEvaluationTypeNameForType,
  getLevelEvaluationTypesForCompany,
} from "src/utils/evaluation-type-utils"
import Select from "src/components/base/forms/select"
import { EvaluationType } from "src/api/types"

const messages = defineMessages({
  leavePageWarning: "Warning!\n\nNavigating away from this page will interrupt the data upload and potentially result in invalid data.",
  sendingErrorNotificationTitle: "Oops! Something went wrong.",
  emptyFormErrorNotificationTitle: "Oops! Something went wrong.",
  emptyFormErrorNotificationMessage: "No invitations were sent.",
  successNotificationTitle: "The invites are sent out!",
  successNotificationMessage: "The related persons will receive an invite in their mailbox.",
  sendInvitesButton: "Send invites",
  sendAsMesssage: "Send as",
  clearFormDataButton: "Clear",
  comingSoonTooltip: "Coming soon!",
  uploadCsvButton: "Upload CSV",
  addMoreUsersButton: "Add more users",
  orBetweenButtons: "or",
  inviteSettings: "Settings",
})

const Invites = () => {
  /* The amount of invite input rows that are being displayed. */
  const [inviteForms, setInviteForms] = useState(initalState)
  const [companyUUID, setCompanyUUID] = useState("")
  const [userUUID, setUserUUID] = useState("")
  const [settingsVisible, setSettingsVisible] = useState(false)
  const [selectedIntroduction, setSelectedIntroduction] = useState({ code: undefined, texten: "", textfr: "", textnl: "", textde: "" })
  const [data, setData] = useState()

  const user = useUser()

  // Sync state with localStorage
  const [cachedValueString, setCachedValueString] = useLocalStorage("invites-form-state", JSON.stringify(initalState))

  useEffect(() => {
    setCachedValueString(JSON.stringify(inviteForms))
  }, [JSON.stringify(inviteForms)])

  useEffectOnce(() => {
    setInviteForms(JSON.parse(cachedValueString))
  }, [cachedValueString])

  useEffect(() => {
    if (user && user.company && user.user) {
      setCompanyUUID(user.company.uuid)
      setUserUUID(user.user.uuid)
    }
  }, [user])

  /* Success modal after clicking on send invites */
  const [showValidations, setShowValidations] = useState(false)

  const formatMessage = useFormatMessage()
  const { loading: bulkLoading, error: bulkError, createInviteList } = useCreateInviteList()
  /* Method adding a new invite form to state */
  const addNewInviteForm = () => {
    setInviteForms([
      ...inviteForms,
      {
        firstname: "",
        lastname: "",
        email: "",
        languageTest: "fr",
        languageUI: "fr",
        requesttimestamp: moment().format("YYYY-MM-DDTHH:mm"),
        signalfinished: 0,
        theirref1: "",
      },
    ])
  }

  const papaparseOptions = {
    header: true,
    dynamicTyping: true,
    skipEmptyLines: true,
    transformHeader: header => header.toLowerCase().replace(/\W/g, "_"),
  }

  const { loading, createInvite } = useCreateInvite()

  const handleSubmit = async () => {
    if (data) {
      handleBulkCreate(data)
      return
    }
    const nonEmptyForms = inviteForms.filter(form => !isEmptyForm(form))
    if (nonEmptyForms.length === 0) {
      notifications.error(formatMessage(messages.emptyFormErrorNotificationTitle), formatMessage(messages.emptyFormErrorNotificationMessage))
      return
    }
    // CHECK INPUT
    const errorDetected = nonEmptyForms.reduce((hasPreviousError, inviteForm) => {
      return hasPreviousError || hasErrors(getErrors(inviteForm))
    }, false)

    if (errorDetected) {
      setShowValidations(true)
      return
    }

    try {
      await forEach(nonEmptyForms, async invite => {
        invite["requesttimestamp"] = moment(invite.requesttimestamp).toISOString()
        await createInvite(
          {
            ...invite,
            requesttemplateuuid: selectedIntroduction.uuid ? selectedIntroduction.uuid : undefined,
            letypecode: askForEvalType ? evalType : getBaseEvaluationTypeForCompany(company),
          },
          companyUUID,
          userUUID
        )
      })
      setInviteForms(initalState) // todo jensen do lang => lang.value
      setShowValidations(false)
      notifications.success(formatMessage(messages.successNotificationTitle), formatMessage(messages.successNotificationMessage))
    } catch (err) {
      notifications.error(formatMessage(messages.sendingErrorNotificationTitle), err.message, err.details)
    }
  }

  // check if they are allowed to create multiple types
  const company = useCurrentCompany()
  const [evalType, setEvaltype] = useState(EvaluationType.STANDARD)
  const evalTypes = getLevelEvaluationTypesForCompany(company)
  const askForEvalType = evalTypes.length > 1
  const evalTypeOptions = evalTypes.map(evalType => ({
    name: _.startCase(getLevelEvaluationTypeNameForType(evalType)),
    value: evalType,
  }))

  const handleBulkCreate = async data => {
    try {
      const invitations = data.map(el => ({
        firstname: el.firstname,
        lastname: el.lastname,
        commonname: `${el.firstname} ${el.lastname}`,
        email: el.email,
        commlangcode: el.commlangcode,
        evaluatedlanguage: el.evaluatedlanguage,
        requesttimestamp: moment(el.requesttimestamp).toISOString(),
        companyuuid: companyUUID,
        hruuid: userUUID,
        theirref1: el.organisation,
        signalfinished: el.notificationsOn,
        letypecode: askForEvalType ? evalType : getBaseEvaluationTypeForCompany(company),
        requesttemplateuuid: selectedIntroduction.uuid ? selectedIntroduction.uuid : undefined,
      }))
      await createInviteList(invitations, companyUUID)
      if (bulkError) {
        notifications.error(formatMessage(messages.sendingErrorNotificationTitle), bulkError)
      } else {
        notifications.success(formatMessage(messages.successNotificationTitle), formatMessage(messages.successNotificationMessage))
      }
    } catch (err) {
      notifications.error(formatMessage(messages.sendingErrorNotificationTitle), err.message, err.details)
    }
  }

  return (
    <>
      <div className="flex flex-col ">
        <div className="w-full bg-white shadow border rounded">
          <GreenLine />
          <div className="">
            <Header headers={headers} />
            <div className={`pt-4 px-5 ${!askForEvalType && "mb-8"}`}>
              {inviteForms.map((invite, idx) => (
                <Invite
                  languageOptionsTest={languages.languageOptionsTest}
                  languageOptionsUI={languages.languageOptionsUI}
                  invite={invite}
                  setInvite={invite => setInviteForms(inviteForms.map((v, i) => (i === idx ? invite : v)))}
                  formValidation={showValidations && !isEmptyForm(invite) ? getErrors(invite) : undefined}
                  key={`${idx}`}
                />
              ))}
            </div>
            {bulkLoading && (
              <div className="flex flex-col justify-center items-center mt-8 mb-8">
                <Loader />
              </div>
            )}
          </div>
          {askForEvalType && (
            <div className="flex items-center justify-end space-x-2 px-5 pt-2 pb-5">
              <div className="pt-2">{formatMessage(messages.sendAsMesssage)}:</div>
              <div>
                <Select
                  bootstrapped
                  name="evalType"
                  options={evalTypeOptions}
                  onChange={value => setEvaltype(value)}
                  value={evalType}
                  required
                  className="mt-2"
                />
              </div>
            </div>
          )}
        </div>
        <div className="flex flex-row items-center justify-between flex-wrap">
          <div>
            <CSVReader
              cssClass="bg-white flex justify-center outline-none items-center py-2 px-4 my-2 rounded border-2 cursor-pointer font-bold flex-shrink text-xs"
              parserOptions={papaparseOptions}
              onFileLoaded={data => setData(data)}
            />
          </div>
          <div className="flex flex-row">
            <Button onClick={() => setSettingsVisible(true)}>{formatMessage(messages.inviteSettings)}</Button>
            <Button onClick={() => setInviteForms(initalState)} className="ml-2">
              {formatMessage(messages.clearFormDataButton)}
            </Button>
            <Button onClick={addNewInviteForm} className="ml-2">
              {formatMessage(messages.addMoreUsersButton)}
            </Button>
            <Button primary className="ml-2" onClick={handleSubmit} loading={loading}>
              {formatMessage(messages.sendInvitesButton)}
            </Button>
          </div>
        </div>
      </div>
      <InviteSettingsModal
        companyUUID={companyUUID}
        visible={settingsVisible}
        onClose={() => setSettingsVisible(false)}
        setSelectedIntroduction={setSelectedIntroduction}
        selectedIntroduction={selectedIntroduction}
      />
    </>
  )
}

export default Invites

const initalState = [
  {
    firstname: "",
    lastname: "",
    email: "",
    companyID: "",
    languageTest: "fr",
    languageUI: "fr",
    requesttimestamp: moment().format("YYYY-MM-DDTHH:mm"),
    signalfinished: 0,
    theirref1: "",
  },
  {
    firstname: "",
    lastname: "",
    email: "",
    companyID: "",
    languageTest: "fr",
    languageUI: "fr",
    requesttimestamp: moment().format("YYYY-MM-DDTHH:mm"),
    signalfinished: 0,
    theirref1: "",
  },
  {
    firstname: "",
    lastname: "",
    email: "",
    companyID: "",
    languageTest: "fr",
    languageUI: "fr",
    requesttimestamp: moment().format("YYYY-MM-DDTHH:mm"),
    signalfinished: 0,
    theirref1: "",
  },
  {
    firstname: "",
    lastname: "",
    email: "",
    companyID: "",
    languageTest: "fr",
    languageUI: "fr",
    requesttimestamp: moment().format("YYYY-MM-DDTHH:mm"),
    signalfinished: 0,
    theirref1: "",
  },
]
