import { getCompanyList } from "src/api"
import { onLogout } from "../account/actions"

export const setCompanies = companies => ({
  type: "companies/SET_COMPANIES",
  payload: companies,
})

export const setLoading = loading => ({
  type: "companies/SET_LOADING",
  payload: loading,
})

export const setError = error => ({
  type: "companies/SET_ERROR",
  payload: error,
})

export const fetchCompanies = () => async dispatch => {
  dispatch(setLoading(true))
  try {
    const companies = await getCompanyList()
    dispatch(setCompanies(companies))
  } catch (error) {
    dispatch(setError(error))
    if ((error.code = "ForbiddenAccess" && error.message === "No valid authentication")) {
      dispatch(onLogout())
    }
  } finally {
    dispatch(setLoading(false))
  }
}
