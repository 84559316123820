/* This file exports the headers of the table in the invites page's modal */
import { defineMessages } from "gatsby-plugin-intl"

const messages = defineMessages({
  firstNameHeader: "First Name",
  lastNameHeader: "Last Name",
  emailHeader: "Email",
  organisationHeader: "Org.",

  testLanguageHeader: "Test",
  interfaceLanguageHeader: "Interface",
  requestTimestamp: "Send on",
  notificationsHeader: "Notify",
})

export default [
  {
    id: "firstname",
    text: messages.firstNameHeader,
    width: "w-2/12",
  },
  {
    id: "lastname",
    text: messages.lastNameHeader,
    width: "w-2/12",
  },
  {
    id: "Email",
    text: messages.emailHeader,
    width: "w-2/12",
  },
  {
    id: "theirref1",
    text: messages.organisationHeader,
    width: "w-1/12",
  },
  {
    id: "requesttimestamp",
    text: messages.requestTimestamp,
    width: "w-2/12",
  },
  {
    id: "Notifications",
    text: messages.notificationsHeader,
    width: "w-1/12",
  },
  {
    id: "Test",
    text: messages.testLanguageHeader,
    width: "w-1/24",
  },
  {
    id: "Interface",
    text: messages.interfaceLanguageHeader,
    width: "w-1/12",
  },
]
