import React from "react"
import TextInput from "./../../../base/forms/textInput"
import Select from "./../../../base/forms/select"
import { defineMessages } from "gatsby-plugin-intl"
import useFormatMessage from "../../../../utils/hooks/useFormatMessage"
import DateInput from "../../../base/forms/dateInput"
import CheckBoxInvite from "src/components/base/forms/checkBoxInvite"

const messages = defineMessages({
  nameRequiredError: "Required",
  nameShouldBeTextError: "Name should be text",
  numberRequiredError: "Required",
  numberShouldBePositiveNumber: "Number should be positive",
  numberShouldBeNumberError: "Number should be number",
  emailInvalidError: "Email invalid",
  firstNameInputPlaceholder: "Lenny",
  lastNameInputPlaceholder: "Garcia",
  emailInputPlaceholder: "lenny@apple.com",
  organisationPlaceholder: "Atolo",
})

const isInvalid = (attribute, formValidation) => formValidation && !!formValidation[attribute]

const Invite = ({ languageOptionsTest, languageOptionsUI, setInvite, invite, formValidation }) => {
  const handleChange = e => {
    setInvite({ ...invite, [e.target.name]: e.target.value })
  }

  const handleTimestampChange = iso => {
    setInvite({ ...invite, requesttimestamp: iso })
  }
  const handleSignalChange = value => {
    setInvite({ ...invite, signalfinished: value ? 1 : 0 })
  }
  const formatMessage = useFormatMessage()
  return (
    <div className="flex text-md mt-4">
      <div className="w-2/12 px-1 flex justify-center items-center">
        <TextInput
          name="firstname"
          placeholder={formatMessage(messages.firstNameInputPlaceholder)}
          className={`${isInvalid("firstname", formValidation) ? "border-red-500" : ""}`}
          value={invite.firstname}
          onChange={handleChange}
          required
          invalid={isInvalid("firstname", formValidation)}
          errors={formValidation && formValidation.firstname && [formatMessage(messages[formValidation.firstname])]}
        />
      </div>
      <div className="w-2/12 px-1 flex justify-center items-center">
        <TextInput
          name="lastname"
          placeholder={formatMessage(messages.lastNameInputPlaceholder)}
          className={`${isInvalid("lastname", formValidation) ? "border-red-500" : ""}`}
          value={invite.lastname}
          onChange={handleChange}
          required
          invalid={isInvalid("lastname", formValidation)}
          errors={formValidation && formValidation.lastname && [formatMessage(messages[formValidation.lastname])]}
        />
      </div>

      <div className="w-2/12 px-1 flex justify-center items-center">
        <TextInput
          name="email"
          placeholder={formatMessage(messages.emailInputPlaceholder)}
          className={`${isInvalid("email", formValidation) ? "border-red-500" : ""}`}
          value={invite.email}
          onChange={handleChange}
          required
          invalid={isInvalid("email", formValidation)}
          errors={formValidation && formValidation.email && [formatMessage(messages[formValidation.email])]}
        />
      </div>
      <div className="w-1/12 px-1 flex justify-center items-center">
        <TextInput
          name="theirref1"
          placeholder={formatMessage(messages.organisationPlaceholder)}
          value={invite.theirref1}
          onChange={handleChange}
          required
        />
      </div>
      <div className="w-2/12 flex justify-center items-center">
        <DateInput
          name="requesttimestamp"
          className="w-full text-2xs md:w-3/12 hidden md:flex appearance-none disp"
          onChange={handleTimestampChange}
          value={invite.requesttimestamp}
          required
        />
      </div>
      <div className="w-1/12 px-1 flex justify-center items-center">
        <CheckBoxInvite name="signalfinished" value={invite.signalfinished === 1 ? true : false} onChange={handleSignalChange} required />
      </div>
      <div className="w-1/24  flex justify-center items-center">
        <Select
          name="languageTest"
          options={languageOptionsTest}
          onChange={value => setInvite({ ...invite, languageTest: value })}
          value={invite.languageTest}
          required
        />
      </div>
      <div className="w-1/24 flex justify-center items-center">
        <Select
          name="languageUI"
          options={languageOptionsUI}
          onChange={value => setInvite({ ...invite, languageUI: value })}
          value={invite.languageUI}
          required
        />
      </div>
    </div>
  )
}

export default Invite
