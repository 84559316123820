export type UUID = string

export type SessionToken = string

export type Timestamp = string // 2019-10-17T09:44:44+00:00

export enum EvaluationType {
  "STANDARD" = "atoloeval.standard",
  "EASY" = "protectas.v1",
}

export type Person = {
  uuid: UUID
  firstname: string
  lastname: string
  commonname: string
  email: string
  companyuuid: UUID
  company: Company
  levelevaluations: LevelEvaluation[]
  commlangcode: string
  isevaluatedperson: boolean
  ishrmanager: boolean
  isetolo: boolean
  theirref1: string
  theirref2: string
}

export type Invitation = {
  uuid: UUID
  invitationname: string
  token: string
  language: string
  maxevaluations: number
  letypecode: EvaluationType
  totalevaluations: number
  evaluatedlanguage: string
  unusedevaluations: number
  expirestimestamp: Timestamp
  startstimestamp: Timestamp | null
  company: Company
}

export type HRManager = Person

export type EtoloManager = Person

export type Company = {
  uuid: UUID
  companyname: string
  totalevaluations: number
  unusedevaluations: number
  numberofpersons: number
  maxevaluationtypes: any
  sendautomaticreminder1after: number
  sendautomaticreminder2after: number
}

export type Language = {
  code: string
  nameen: string
  namenl: string
  namefr: string
}

export type LevelEvaluation = {
  uuid: UUID
  personuuid: UUID
  letypecode: EvaluationType
  evaluatedlanguage: string
  statusid: LevelEvaluationStatus
  invitationtimestamp: Timestamp
  expirestimestamp: Timestamp
  startedtimestamp: Timestamp | null
  finishedtimestamp: Timestamp | null
  currentstep: number
  finallevel: string | null
  token: string
  totalansweringtime: number | null
  person: Person
}

export type EvaluationQuestion = {
  uuid: UUID
  step: number
  levelevaluationuuid: string
  questiontext: string
  instructiontext: string
  possibleanswers: {
    uuid: UUID
    answertext: string
  }[]
}

export type PossibleAnswer = {
  uuid: UUID
  questionuuid: UUID
  answertext: string
}

export type LevelEvaluationStatus = 0 | 10 | 20 | 30 | 40 | 50

export type LoginResponse = {
  value: SessionToken
  apiversion: string
  user: Person
  company: Company
}

export type StaticString = {
  code: string
  texten: string
  textnl: string
  textfr: string
}
