import { useState } from "react"
import { patchCompany } from "src/api"
import { fetchCompany } from "../../store/company/actions"
import { useDispatch } from "react-redux"
import { fetchCompanies } from "../../store/companies/actions"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"
import { getAPI_CALL_STARTED } from "../gaConstants"
export default () => {
  const [error, setError] = useState(undefined)
  const [loading, setLoading] = useState(false)

  const dispatch = useDispatch()
  const execute = async company => {
    try {
      trackCustomEvent(getAPI_CALL_STARTED("UPDATE_COMPANY"))

      setLoading(true)
      setError(undefined)
      const data = await patchCompany(company)
      dispatch(fetchCompany(company.uuid))
      dispatch(fetchCompanies())
      return data
    } catch (error) {
      Sentry.captureException(error)

      setError(error.message)
      throw error
    } finally {
      setLoading(false)
    }
  }

  return { error, loading, patchCompany: execute }
}
