import { useState } from "react"
import { createListLevelEvaluations } from "src/api"
import { useDispatch } from "react-redux"
import { fetchLevelEvaluations } from "../../store/levelEvaluations/actions"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"
import { getAPI_CALL_STARTED } from "../gaConstants"

export default () => {
  const [error, setError] = useState(undefined)
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const execute = async (invites, companyuuid) => {
    try {
      trackCustomEvent(getAPI_CALL_STARTED("CREATE_LEVEL_EVALUATION_LIST"))

      setLoading(true)
      setError(undefined)
      const data = await createListLevelEvaluations(invites, companyuuid)
      const errorData = data.filter(d => !!d.code)
      if (errorData) {
        setError("Some of the invitations have not been created, please check the format of the data.")
      }
      dispatch(fetchLevelEvaluations(companyuuid))
      return data
    } catch (error) {
      Sentry.captureException(error)

      setError(error.message)
      throw error
    } finally {
      setLoading(false)
    }
  }

  return { error, loading, createInviteList: execute }
}
