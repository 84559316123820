import React from "react"

const CheckBoxInvite = ({ value, onChange, className }) => {
  return (
    <div className={`flex items-center cursor-pointer ${className}`} onClick={() => onChange(!value)}>
      <input type="checkbox" checked={value} className="mr-2" />
    </div>
  )
}

export default CheckBoxInvite
