import React from "react"
import { ScaleLoader } from "react-spinners"

const Loader = () => {
  return (
    <div className="w-full flex justify-center my-10">
      <ScaleLoader color="#ddd" />
    </div>
  )
}

export default Loader
