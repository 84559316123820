import React, { useState, useEffect } from "react"
import ModalContainer from "../../../base/modals/modalContainer"
import Title from "../../../layout/title"
import { defineMessages } from "gatsby-plugin-intl"
import useFormatMessage from "../../../../utils/hooks/useFormatMessage"
import TextInput from "../../../base/forms/textInput"
import usePatchCompany from "../../../../utils/hooks/usePatchCompany"
import { fetchCompany } from "../../../../store/company/actions"
import { useSelector } from "react-redux"
import { companySelector } from "../../../../store/company/selectors"
import Loader from "../../../base/loaders/loader"
import Button from "../../../base/buttons/regular"
import notifications from "../../../../utils/notifications"
import { useDispatch } from "react-redux"
import { stringsSelector } from "../../../../store/strings/selectors"
import Dropdown from "../../../base/dropdown"
import { getName } from "./../../settings/utils/utils"
import { fetchStrings } from "../../../../store/strings/actions"

const messages = defineMessages({
  inviteSettingsTitle: "Settings",
  reminderInputPlaceholder: "X days",
  saveSettings: "Save",
  saveSettingsErrorTitle: "Oops! Something went wrong.",
  saveSettingsSuccesMessage: "The reminders will be sent after the provided amount of days",
  saveSettingsSuccesTitle: "Saved!",
  settingsFirstReminderLabel: "A first reminder should be sent after ",
  settingsSecondReminderLabel: "A second reminder should be sent after",
  settingsTemplateMessage: "Pick a template for the email:",
  Template: "Choose a template",
})

const InviteSettingsModal = ({ visible, onClose, companyUUID, setSelectedIntroduction, selectedIntroduction }) => {
  const [firstReminder, setFirstReminder] = useState()
  const [secondReminder, setSecondReminder] = useState()
  const { loading: stringsLoading, results: strings } = useSelector(stringsSelector(companyUUID))
  const [stringsInState, setStringsInState] = useState(undefined)

  useEffect(() => {
    if (strings) {
      setStringsInState(strings)
    }
    if (!!strings && strings.length > 0 && !selectedIntroduction.code) {
      setSelectedIntroduction({ ...strings[0] })
    }
  }, [strings])
  const dispatch = useDispatch()
  const formatMessage = useFormatMessage()

  useEffect(() => {
    if (companyUUID) {
      dispatch(fetchCompany(companyUUID))
      dispatch(fetchStrings(companyUUID, "nl"))
    }
  }, [companyUUID])

  const { patchCompany, loading: updateCompanyLoading } = usePatchCompany()
  const { result: company, loading: companyLoading } = useSelector(companySelector(companyUUID))

  useEffect(() => {
    if (company) {
      setFirstReminder(company.sendautomaticreminder1after)
      setSecondReminder(company.sendautomaticreminder2after)
    }
  }, [company])

  const handle = async () => {
    try {
      await patchCompany({ ...company, sendautomaticreminder1after: firstReminder, sendautomaticreminder2after: secondReminder })
      notifications.success(formatMessage(messages.saveSettingsSuccesTitle), formatMessage(messages.saveSettingsSuccesMessage))
    } catch (error) {
      notifications.error(formatMessage(messages.saveSettingsErrorTitle), error.message, error.details)
    }
  }

  return (
    <ModalContainer className="w-128" visible={visible} onClose={onClose}>
      <div className="px-10 pb-12">
        <div className="flex flex-col justify-between mt-2">
          <Title>{formatMessage(messages.inviteSettingsTitle)}</Title>
          {companyLoading && company ? (
            <Loader />
          ) : (
            <div>
              <div className="flex flex-row align-center">
                <p className="mt-2">{formatMessage(messages.settingsFirstReminderLabel)} </p>
                <TextInput
                  type="number"
                  value={firstReminder}
                  onChange={e => setFirstReminder(e.target.value)}
                  placeholder={formatMessage(messages.reminderInputPlaceholder)}
                  noWidth
                  className="w-20"
                />
                <p className="mt-4 ml-1"> days.</p>
              </div>
              <div className="flex w-full">
                <p className="mt-2">{formatMessage(messages.settingsSecondReminderLabel)} </p>
                <TextInput
                  type="number"
                  value={secondReminder}
                  onChange={e => setSecondReminder(e.target.value)}
                  placeholder={formatMessage(messages.reminderInputPlaceholder)}
                  noWidth
                  className="w-20"
                />

                <p className="mt-4 ml-1"> days.</p>
              </div>
              <div className="flex items-center justify-between mr-2 mt-2">
                <p className="mt-2">{formatMessage(messages.settingsTemplateMessage)}</p>

                {stringsInState && (
                  <Dropdown
                    options={stringsInState.map(string => {
                      return { text: getName(string), onClick: () => setSelectedIntroduction({ ...string, name: getName(string) }) }
                    })}
                    title={
                      selectedIntroduction.code && getName(selectedIntroduction) ? getName(selectedIntroduction) : formatMessage(messages.Template)
                    }
                  />
                )}
              </div>
            </div>
          )}
          <Button primary onClick={handle} loading={updateCompanyLoading} className="mt-4">
            {formatMessage(messages.saveSettings)}
          </Button>
        </div>
      </div>
    </ModalContainer>
  )
}

export default InviteSettingsModal
