import React, { useState } from "react"
import Datepicker from "react-datepicker"
import { parseISO, formatISO } from "date-fns"

const DateInput = ({ className = "", value, onChange, name, required = false }) => {
  const date = parseISO(value)

  return (
    <Datepicker
      selected={date}
      onChange={date => onChange(formatISO(date))}
      showTimeSelect
      timeFormat="HH:mm"
      timeIntervals={1}
      timeCaption="time"
      dateFormat="dd/MM/yyyy HH:mm"
      name={name}
    />
  )

  // return (
  //   <input
  //     type="datetime-local"
  //     name={name}
  //     value={value}
  //     onChange={onChange}
  //     required={required}
  //     className={`appearance-none text-gray-600 border-none ${className}`}
  //   />
  // )
}

export default DateInput
