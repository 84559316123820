import React from "react"
import { MdError } from "react-icons/md"

const TextInput = ({
  placeholder = "",
  className = "",
  value,
  type = "text",
  onChange,
  name = "",
  required = false,
  invalid = false,
  errors = [],
  autoFocus = false,
  noWidth = false,
}) => {
  return (
    <div className={`${className} flex flex-1`}>
      <input
        className={inputStyle({ invalid, noWidth })}
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        name={name}
        autoFocus={autoFocus}
        required={required}
      />

      <div className={` ${invalid ? "flex" : "hidden"} items-center justify-center mt-1  text-xs text-red-400 font-medium`}>
        <MdError className="mr-1" /> {(errors && errors.map(e => e)) || "Not valid"}
      </div>
    </div>
  )
}

const inputStyle = (props = {}) => `
  bg-transparent
  border
  rounded
  appearance-none
  truncate
  border-b
  text-gray-800
  px-2
  py-3
  mt-1
  mb-1
  min-w-0
  max-w-xs
  ${props.noWidth ? "" : "w-0"}
  flex-1
  max-w-xs
  text-sm
  leading-tight
  focus:outline-none
  ${props.invalid ? "border-red-400" : ""}
`

export default TextInput
