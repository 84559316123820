import React from "react"
import { useSelector } from "react-redux"
import Invites from "../../components/admin/invites"
import AdminLoginGuard from "../../components/admin/AdminLoginGuard"
import Layout from "../../components/layout"
import atoloLogo from "../../images/logos/logo.png"
import { companySelector } from "../../store/company/selectors"
import { Helmet } from "react-helmet"
import { useCompanyUUID } from "../../utils/hooks/useCompanyUUID"
import { isCustomCompanyLogo } from "../../utils/hooks/useCompanyLogo"
import { defineMessages } from "gatsby-plugin-intl"
import useFormatMessage from "src/utils/hooks/useFormatMessage"

const m = defineMessages({
  youHave: "You have",
  testsLeft: "tests left.",
})

const InvitesPage = () => {
  const companyID = useCompanyUUID()
  const { result: company } = useSelector(companySelector(companyID))

  const t = useFormatMessage()

  const showEvaluations = company && typeof company?.unusedevaluations === "number"
  const showCustomAtolo = isCustomCompanyLogo(companyID)

  return (
    <AdminLoginGuard>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Atolo - Eval | Send Requests</title>
      </Helmet>
      <Layout type="hr">
        <div className="container h-full flex-1 max-w-4xl w-4/5 md:pt-5 md:pb-20">
          {(showEvaluations || showCustomAtolo) && (
            <div className="flex w-full items-center mb-4">
              {showEvaluations && (
                <div className="text-xs">
                  {t(m.youHave)} <span className="font-semibold">{company?.unusedevaluations}</span> {t(m.testsLeft)}
                </div>
              )}
              <div className="flex-1" />
              {showCustomAtolo && (
                <img className="p-0 m-0 cursor-pointer -my-5 -mx-3" style={{ width: 120, objectFit: "contain" }} src={atoloLogo} alt="atolo" />
              )}
            </div>
          )}
          <Invites />
        </div>
      </Layout>
    </AdminLoginGuard>
  )
}

export default InvitesPage
